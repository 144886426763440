import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import { LargeRarr } from '~components/Svg'
import { mobile } from '~styles/global'

const Page = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<DataViewer data={page} name="page"/>
			<Header>
				<Heading color={page.keyColour}>
					Search
				</Heading>
			</Header>
			<Section>
				<SearchForm>
					<SearchInput className='h4' placeholder='Enter search term'/>
					<Submit>
						<RightArrow />
					</Submit>
				</SearchForm>
			</Section>
		</>
	)
}

const Header = styled(Section)`
	margin-bottom: 90px;
`
const Heading = styled.h1`
	color: ${ props => props.color };
	grid-column: span 12;
`
const SearchInput = styled.input`
	flex: 1;
	background-color: transparent;
	border: transparent;
	border-bottom: 1px solid var(--white);
	opacity: 0.5;
	height: 50px;
	color: var(--white);
	:focus {
		opacity: 1;
	}
	::placeholder{
		font-family: inherit;
		font-size: inherit;
		opacity: 1;
		color: var(--white);
	}
	${mobile}{
		height: 34px;
	}
`
const SearchForm = styled.form`
	display: flex;
	grid-column: span 12;
`
const Submit = styled.button`
	width: 66px;
	opacity: 0.5;
	${mobile}{
		width: 34px;
	}
`
const RightArrow = styled(LargeRarr)`
	position: relative;
	top: -0.07em;
	${mobile}{
		top: -0.09em;
	}
`

Page.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query PageQuery($slug: String) {
    page: sanityPage(slug: {current: {eq: $slug}}) {
			seo{
				...seo
			}
    }
  }
`

export default Page